import React, { useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import appointmentservice from "../../../../../../../services/appointmentservice";
import { toast } from "react-hot-toast";
import { BiPlus, BiSolidCalendarWeek } from "react-icons/bi";
import { appointmentLabel } from "../../labels";
import clientService from "../../../../../../../services/clientservice";
import { convertTimestampToUTC } from "../../../../../../../utils";
import useSettings from "../../../../../../../hooks";
import { FaFilter } from "react-icons/fa6";
import { MdOutlineClear } from "react-icons/md";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import AutocompleteClientDropDown from "../../../../../../../hooks/useClientList";
import ClientComponent from "./component/ClientComponent";
import EmployeeComponent from "./component/EmployeeComponent";
import {
  eventMeetingperson,
  eventStyleAppointmentCalendarGetter,
} from "../calendarutils";
import { AppointmentPopup } from "../AppointmentPopup";
import DeleteAlert from "../DeleteAlert";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import MainCalendar from "../MainCalendar";
import { useTranslation } from "react-i18next";

registerLocale("fr", fr);
require("moment/locale/fr");

function Calendars({ id }) {
  const { empemail } = useSettings();
  const [clientIdData, setClientIdData] = React.useState(null);
  const [allEvents, setAllEvents] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showEmpSidebar, setShowSideEmployee] = useState(false);
  const [deleventId, setDelEventId] = React.useState("");
  const { register, setValue, reset } = useForm();
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [clientList, setClientList] = React.useState([]);
  const [empId, setEmployeId] = React.useState("");
  const [clearClientFlag, setClearflag] = React.useState(false);
  const [updateAppointment, setupdateAppointment] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);
  const handleEmployeeChange = (e) => {
    setEmployeId(e.target.value);
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {`${allEvents?.length}  Total de rendez-vous mensuels`}
    </Tooltip>
  );

  const filterAppointmentHandler = () => {
    const payload = {
      clientId: clientIdData?.client_id || id,
      employeeId: empId || "",
    };
    appointmentservice
      .getappointmentByEmployee(payload)
      .then((res) => {
        if (res) {
          const finalapp = res?.map((app) => {
            const startTimestamp = app?.start_date_and_time * 1000;
            return {
              ...app,
              title: app.additional_information,
              start: new Date(startTimestamp + app.start_time * 1000),
              end: new Date(startTimestamp + app.end_time * 1000),
              description: app?.meeting_address,
              id: app?.appointment_id,
            };
          });
          setAllEvents(finalapp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearAppointmentHandler = () => {
    setEmployeId("");
    setClearflag(true);
    setClientIdData(null);
    appointmentservice
      .viewAppointment()
      .then((res) => {
        if (res) {
          const finalapp = res?.map((app) => {
            const startTimestamp = parseInt(app?.start_date_and_time) || 0;
            const startDatetime = startTimestamp + parseInt(app?.start_time);
            const endDatetime = startTimestamp + parseInt(app?.end_time);
            const starting = convertTimestampToUTC(startDatetime);
            const ending = convertTimestampToUTC(endDatetime);
            return {
              ...app,
              title: app?.additional_information,
              start: starting,
              end: ending,
              description: app?.meeting_address,
            };
          });
          setAllEvents(finalapp);
        }
      })
      .catch((err) => console.log(err));
  };

  const appointementListApi = () => {
    const payload = {
      employeeId: "",
    };
    appointmentservice
      .getappointmentByEmployee(payload)
      .then((res) => {
        if (res) {
          const finalapp = res?.map((app) => {
            return {
              ...app,
              title: app.additional_information,
              start: new Date(
                app?.start_date_and_time * 1000 + app.start_time * 1000
              ),
              end: new Date(
                app?.start_date_and_time * 1000 + app.end_time * 1000
              ),
              description: app?.meeting_address,
              id: app?.appointment_id,
              name: eventMeetingperson(app?.meeting_person, empemail),
            };
          });
          setAllEvents(finalapp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    appointementListApi();
  }, [id, empemail, updateAppointment, showSidebar, showEmpSidebar]);

  React.useEffect(() => {
    if (id)
      clientService
        .getClientInfo()
        .then((client) => {
          const clientlist = client?.map((e) => {
            return {
              label: `${e.surname} ${e.client_name}`,
              value: e?.client_id,
            };
          });
          setClientList(clientlist);
        })
        .catch((error) => {
          console.error(error);
        });
  }, [id]);

  const handleSelectAppointment = (e) => {
    if ((e?.type === "EMPLOYEE" && e?.meeting_person) || e?.type === "CLIENT") {
      setShowDelete(true);
    } else {
      setShowDelete(false);
    }
    setShow(true);

    setSelectedEvent(e);
  };

  const handleClose = () => {
    setShow(false);
    return;
  };

  const onhandleDeleteConfirm = () => {
    try {
      if (deleventId) {
        appointmentservice
          .deleteAppointments(deleventId)
          .then(async (res) => {
            if (res) {
              toast.success("Supprimé avec succès");
              setShow(false);
              setDelEventId("");
              setShowDelete(false);
              setShowDeleteConfirm(false);
              await appointementListApi();
            }
          })
          .catch((err) => {
            if (err) toast.error("Erreur lors de la suppression");
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteEvent = (eventId) => {
    setShow(false);
    setShowDeleteConfirm(true);
    setDelEventId(eventId);
  };

  const closesidebar = () => {
    setShowSidebar(false);
    reset({
      client_id: "",
      email: "",
      meeting_address: "",
      meeting_mode: "",
      end_time: "",
      meeting_person: [{ key: "", value: "" }],
      organizer: "",
      start_date_and_time: "",
      start_time: "",
      additional_information: "",
      description: "",
    });
    setupdateAppointment(null);
  };

  const closeEmployeeSidebar = () => {
    setShowSideEmployee(false);
  };
  React.useEffect(() => {
    if (id)
      setValue("client_id", clientList.filter((x) => x.value === id)[0]?.label);
  }, [showSidebar, id, clientList, setValue]);

  const storeClientKeyHandler = (client) => {
    setClientIdData(client);
  };
  const clientChangehandler = (e) => {
    setClearflag(false);
    if (e === "") {
      setClientIdData(null);
    }
  };
  const { t } = useTranslation("global");

  return (
    <>
      <div className="mb-0">
        <div>
          <div className="d-flex mb-2">
            <div className="flex-grow-1 h6 fs-12  pb-2">
              <BiSolidCalendarWeek size={20} className="me-2" />
              {t("Rendez-vous")}
            </div>

            <div>
              <DropdownButton
                id="dropdown-basic-button"
                title={
                  <>
                    <BiPlus className="me-2" size={20} />
                    <span className="me-2  ">{t("Nouveau rendez-vous")}</span>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {allEvents?.length}
                      </span>
                    </OverlayTrigger>
                  </>
                }
              >
                <div style={{ width: "235px" }}>
                  <Dropdown.Item
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowSidebar(true);
                    }}
                  >
                    {t("Cliente")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowSideEmployee(true);
                    }}
                  >
                    {t("Employée")}
                  </Dropdown.Item>
                </div>
              </DropdownButton>
            </div>
          </div>

          <div>
            <div className="row py-2">
              <div className="col-md-3"></div>
              <div className="col-md-9">
                <div className="row text-end ">
                  <div className="col-md-12">
                    <div className="row g-0">
                      <div className="col-md-4">
                        <Form.Select
                          style={{ width: "230px" }}
                          value={empId}
                          onChange={(e) => handleEmployeeChange(e)}
                        >
                          <option value="">
                            <option value="">{t("Liste des employées")}</option>
                          </option>
                          {empemail?.length ? (
                            empemail?.map((emp) => (
                              <option key={emp?.key} value={emp?.value}>
                                {emp?.label}
                              </option>
                            ))
                          ) : (
                            <option value="Nom de l'employée">
                              Nom de l'employée
                            </option>
                          )}
                        </Form.Select>
                      </div>

                      <div className="col-md-4 mb-1">
                        {id ? (
                          <Form.Control
                            {...register("client_id")}
                            disabled={id ? true : false}
                            style={{ width: "280px" }}
                          ></Form.Control>
                        ) : (
                          <div style={{ width: "280px" }}>
                            <AutocompleteClientDropDown
                              onChangeinput={(e) => clientChangehandler(e)}
                              clearflag={clearClientFlag}
                              storeClientKeyHandler={storeClientKeyHandler}
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          onClick={() => filterAppointmentHandler()}
                          className="btn btn-primary btn-sm  "
                          style={{ height: "90%" }}
                        >
                          <FaFilter className="me-2" /> Filtrer
                        </button>
                        <button
                          type="button"
                          onClick={() => clearAppointmentHandler()}
                          class="btn btn-primary btn-sm  ms-3"
                          style={{ height: "90%" }}
                        >
                          <MdOutlineClear className="me-2" /> {t("Effacer")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>

          <ClientComponent
            closesidebar={closesidebar}
            showSidebar={showSidebar}
          />
          <EmployeeComponent
            closesidebar={closeEmployeeSidebar}
            showSidebar={showEmpSidebar}
          />
        </div>
      </div>

      {/* Big calendar */}
      <MainCalendar
        allEvents={allEvents}
        handleSelectScheduler={handleSelectAppointment}
        calendarstyle={eventStyleAppointmentCalendarGetter}
      />

      <AppointmentPopup
        event={selectedEvent}
        empemail={empemail}
        handleDeleteEvent={handleDeleteEvent}
        handleClose={handleClose}
        show={show}
        showDelete={showDelete}
      />

      <DeleteAlert
        onCancelDelete={() => {
          setShowDeleteConfirm(false);
          setDelEventId("");
        }}
        showdelete={showDeleteConfirm}
        handleDeleteConfirm={onhandleDeleteConfirm}
      />
    </>
  );
}

export default Calendars;
