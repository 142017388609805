import React from "react";
import { BsFillPeopleFill } from "react-icons/bs";
import { PiGenderIntersexBold } from "react-icons/pi";
import { MdElderly } from "react-icons/md";
import { useTranslation } from "react-i18next";
  

const DashboardCards = (props) => {
  const { t } = useTranslation("global");

  const { appointmentcount, clientcount ,dashboardresult} = props;
   return (
    <>
      <div class="container">
        {/* <!-- Card deck --> */}
        <div class="card-deck row mb-3">
          <div class="col-xs-12 col-sm-6 col-md-4 d-flex mb-2">
            <div class="card h-100 flex-fill border-0 rounded-4">
              <div class="card-body d-flex flex-column">
                <div className="">
                  <h6 class="card-title">{t("Nombre de clients")}</h6>
                </div>
                <div className="d-flex justify-content-between align-items-end flex-fill">
                  <h6 class="card-text">{dashboardresult?.clients?.count ==0 ? 0 : dashboardresult?.clients?.count}</h6>
                  <BsFillPeopleFill style={{ fontSize: "40px" }} />
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-4 d-flex  mb-2">
            <div class="card h-100 flex-fill border-0 rounded-4">
              <div class="card-body d-flex flex-column">
                <div className="">
                  <h6 class="card-title">{t("Clients par genre")}</h6>
                </div>
                <div className="d-flex justify-content-between align-items-end flex-fill">
                  <div className="list-unstyled">
                    <li class="card-text lh-sm">H - {dashboardresult?.clients?.gender?.male && dashboardresult?.clients?.gender?.male || 0}</li>
                    <li class="card-text lh-sm">F -  {dashboardresult?.clients?.gender?.female && dashboardresult?.clients?.gender?.female || 0} </li>
                    <li class="card-text lh-sm">A - {dashboardresult?.clients?.gender?.other  || 0}  </li>
                  </div>
                  <PiGenderIntersexBold style={{ fontSize: "40px" }} />
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-6 col-md-4 d-flex  mb-2">
            <div class="card h-100 flex-fill border-0 rounded-4">
              <div class="card-body d-flex flex-column">
                <div className="">
                  <h6 class="card-title"> {t("Categories d’age des clients")} </h6>
                </div>
                <div className="d-flex justify-content-between align-items-end flex-fill">
                  <div>
                    {/* <h6 class="card-text text-start">{dashboardresult?.clients?.count}</h6> */}
                    {/* <ul> */}

                    <li class="card-text lh-sm">15-25 ({dashboardresult?.age?.age1})</li>
                    <li class="card-text lh-sm">26-39 ({dashboardresult?.age?.age2})</li>
                    <li class="card-text lh-sm">40-59 ({dashboardresult?.age?.age3})</li>
                    <li class="card-text lh-sm">60 {t("ans et plus")}({dashboardresult?.age?.age4})</li>

                    {/* </ul> */}
                  </div>
                  <MdElderly style={{ fontSize: "40px" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardCards;
