import React from "react";
import { Offcanvas } from "react-bootstrap";
 import { appointmentLabel } from "../../../labels";
import EmployeeAppointments from "../EmployeeAppointments";
import { useTranslation } from "react-i18next";

const EmployeeComponent = ({ closesidebar, showSidebar }) => {
  const { t } = useTranslation('global');
  return (
    <Offcanvas
      scroll={true}
      backdrop={false}
      show={showSidebar}
      placement="end"
      onHide={() => {
        closesidebar();
      }}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title> {t("employeetitle")}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <EmployeeAppointments
          onHide={() => {
            closesidebar();
          }}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
};
export default EmployeeComponent;
