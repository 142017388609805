import instance from "../axiosConfig";
import baseapi from "../baseURL";


const registrationAuthentication =(data)=>{
   const {username,password }= data
   return(
      baseapi.post('/register' ,{username,password})
      .then(response => {
          if (response.status) {
            return response.data;
             }
             
           
      })
      .catch(error => {
        console.error('Error:', error);
      })
  )
 
} 

const loginauthentication =(data)=>{
    const {username,password }= data
     return(
        baseapi.post('/auth/login' ,{username,password})
        .then(response => {
             if (response.data) {
                 sessionStorage.setItem('user', JSON.stringify(response.data));
              }
              return response.data;
        })
        .catch(error => {
          console.error('Error:', error);
        })
    )
   
} 


const forgetByusername =(data)=>{
    return(
      baseapi.post('/auth/finduser' ,data)
      .then(response => {
           if (response.data) {
            return response.data;            }
             
      })
      .catch(error => {
        console.error('Error:', error);
      })
  )
 
} 


const resetPassword =(data)=>{
  return(
    baseapi.post('/auth/resetpassword' ,data)
    .then(response => {
         if (response.data) {
          return response.data;      
              }
           
    })
    .catch(error => {
      console.error('Error:', error);
    })
)

} 

const getpassword =()=>{
  return(
    instance.get('/user/getpassword')
    .then(response => {
         if (response.data) {
          return response.data;      
              }
           
    })
    .catch(error => {
      console.error('Error:', error);
    })
)

} 
const updatePasswordHanlder =(data)=>{
  const {userId,newPassword }= data
   return(
      instance.put('/updatepassword' ,{userId,newPassword})
      .then(response => {
          if (response.data) {
               sessionStorage.setItem('user', JSON.stringify(response.data));
            }
            return response.data;
      })
      .catch(error => {
        console.error('Error:', error);
      })
  )
 
} 

const getuserProfile = () => {
  return instance
    .get(`/getuserprofile`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
 
const logout = () => {
  
  sessionStorage.removeItem("user");
  };

  const authService = { updatePasswordHanlder,forgetByusername,resetPassword,getpassword,
    registrationAuthentication,getuserProfile,
     loginauthentication,
    logout
  };
  export default authService;