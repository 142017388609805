import React from "react";
import Select from "react-select";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Container, Form, Row, Col } from "react-bootstrap";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import DatePicker from "react-datepicker";
import TimePicker from "react-bootstrap-time-picker";
import { ClientApppointmentLabel } from "../../labels";
import useSettings from "../../../../../../../hooks";
import appointmentservice from "../../../../../../../services/appointmentservice";
import { toast } from "react-hot-toast";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function EmployeeAppointments({ data, id, onHide }) {
  const { empemail } = useSettings();
  const [loader, setLoader] = React.useState(false);
  const [employeelist, setEmployeelist] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [serverErrortype, setErrorType] = React.useState([]);
  const  {selectedLanguage}  = useSelector(state => state.language);

  const {
    register,
    handleSubmit,
    reset,
    watch, 
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    defaultValues: {
      email_people: [{ key: "", value: "" }],
    },
  });

   const { fields, append, remove } = useFieldArray({
    control,
    name: "email_people",
  });

  const meeting_persons = watch("meeting_person");

  React.useEffect(() => {
    if (meeting_persons?.length === 0) {
      append({ key: "", value: "" });
    }
  }, [meeting_persons]);

  const meet_mode = [
    { value: "1", label: "En ligne" },
    { value: "2", label: "En personne" },
  ];
  const formData = async (formInfo) => {
    setLoader(true);
    const appoint = [];
    formInfo?.email_people.map((x, i) => {
      appoint.push({
        employee_id: x?.key?.value,
        email: x.value,
        type: "EMPLOYEE",
      });
    });
 
    const organizeremp = formInfo.employee?.value;

    const employeelist = appoint.find((x) => x?.employee_id == organizeremp);
    if (employeelist) {
      setLoader(false);
      setErrorMessage("Employée en double détecté.");
      return;
    }

    const param = {
      organizer_emp: formInfo.employee?.value,
      organizer_email: formInfo.employee_email,
      end_time: formInfo.end_time,
      start_date_and_time: formInfo.start_date_and_time,
      start_time: formInfo.start_time,
      meeting_place: formInfo.meeting_place,
      add_info: formInfo.add_info,
      meeting_mode: formInfo.meeting_mode.value,
      employees: appoint,
    };

    appointmentservice
      .createEmployeeAppointment(param)
      .then((res) => {
        if (res) {
          setLoader(false);
          toast.success("Rendez-vous créé avec succès");
          onHide(false);
          reset();
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response) {
          if (error.response.status === 404) {
            toast.error("Désolé, le planning est déjà pris !");
            setErrorType(error?.response?.data?.msg);
          }
        }
      });
  };
  const { t } = useTranslation('global');

   return (
    <>
      <Container className="my-1">
        <Form onSubmit={handleSubmit(formData)}>
          <div>
            <Form.Group className="mb-3" controlId="formBasicaddinfo">
              <Form.Label>{t("Titre")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={"Titre"}
                {...register("add_info", {
                  required: t("Le titre est requis"),
                })}
              />
              {errors?.add_info && (
                <Form.Text className="text-danger">
                  <p className="text-danger">{errors.add_info.message}</p>
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-1">
              <Form.Label>{t("Organizer name")}</Form.Label>
            </Form.Group>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="employee_name">
                  <Controller
                    control={control}
                    name="employee"
                    rules = {{
                      required: t("Un organisateur est requis"),
                     } }
                    
                    
                    render={({ field }) => (
                      <Select
                        {...field}
                        onChange={(e) => {
                          setEmployeelist(() =>
                            empemail.filter((x) => x.value !== e.value)
                          );
                          setValue(`employee_email`, e.email);
                          field.onChange(e);
                        }}
                        onBlur={field.onBlur}
                        placeholder={t("Sélectionner")}
                        options={empemail}
                      />
                    )}
                  />
                  {errors?.employee && (
                    <p className="text-danger">{t("Un organisateur est requis")}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Control
                  type="email"
                  {...register(`employee_email`, {
                    required: t("required_email"),
                  })}
                  placeholder={t("Courriel")}
                  className="placeholder-color"
                />
                {errors?.employee_email?.value && (
                  <p className="text-danger">{t("Un organisateur est requis")}</p>
                )}
              </Col>
            </Row>

            <Row className="g-2">
              <Col md={6}>
                <Form.Group className="mb-3" controlId="lastname">
                  <Form.Label>
                    {t("start_date")} *
                  </Form.Label>

                  <Controller
                    name={`start_date_and_time`}
                    control={control}
                    {...register(`start_date_and_time`, {
                      required: t("Confirmar contraseña es obligatorio"),
                    })}
                    render={({ field }) => (
                      <>
                        <DatePicker
                          locale="fr"
                          selected={field.value}
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          className="form-control placeholder-color"
                          dateFormat="yyyy/MM/dd"
                          placeholderText="AAAA/MM/JJ"
                          wrapperClassName="datePicker"
                          minDate={new Date()}
                        />
                        {errors?.start_date_and_time && (
                          <p className="text-danger">{t("La date est requise")}</p>
                        )}
                      </>
                    )}
                  />
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group className="mb-3" controlId="client_country">
                  <Form.Label>
                    {t("start_time")}*
                  </Form.Label>
                  <Controller
                    control={control}
                    name="start_time"
                    rules={{
                      required: t("L'heure de début est requise"),
                      validate: (value) => {
                        const startTime = value;
                        const endTime = watch("end_time");

                        if (startTime && endTime) {
                          if (endTime <= startTime) {
                            return t("L'heure de fin doit être postérieure à l'heure de début");
                          }
                        }

                        return true; // Validation passes if there are no errors
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <TimePicker
                          {...field}
                          format={24}
                          step={30}
                        />
                        {errors.start_time && (
                          <p className="text-danger">{errors.start_time.message}</p>
                        )}
                      </>
                    )}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-3" controlId="client_country">
                  <Form.Label>{t("end_time")} *</Form.Label>
                  <Controller
                    control={control}
                    name="end_time"
                              rules={{
                                required: t("L'heure de fin est requise"),
                                validate: (value) => {
                                  const startTime = watch("start_time");
                                  const endTime = value;

                                  if (startTime && endTime) {
                                    if (endTime <= startTime) {
                                      return t("L'heure de fin doit être postérieure à l'heure de début");
                                    }
                                  }

                                  return true; // Validation passes if there are no errors
                                },
                              }}
                              render={({ field }) => (
                                <>
                                  <TimePicker
                                    {...field}
                                    format={24}
                                    step={30}
                                  />
                                  {errors.end_time && (
                                    <p className="text-danger">{errors.end_time.message}</p>
                                  )}
                                </>
                              )}
                            />
                </Form.Group>
              </Col>
            </Row>

            {serverErrortype?.filter((x) => x.type === "ORGANIZER")?.length ? (
              <div className="text-danger mb-2 text-center">
                Ce rendez-vous est un conflit avec un autre rendez-vous de votre calendrier
              </div>

            ) : ""}

            <Form.Group className="mb-3" controlId="formBasicEmpl">
              <Form.Label>{t("Meeting place")}</Form.Label>
              <Form.Control
                type="text"
                {...register("meeting_place", {
                  required: t("La date est requise"),
                })}
              />
              {errors?.meeting_place && (
                <Form.Text className="text-danger">
                  <p className="text-danger">
                    {t("Un lieu de rendez-vous est obligatoire")}
                  </p>
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("Meeting mode")}</Form.Label>{" "}
              <Controller
                control={control}
                name="meeting_mode"
                rules={{ required: t("Le client est requis") }}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    onBlur={field.onBlur}
                    placeholder={t("Sélectionner")}
                    options={meet_mode}
                  />
                )}
              />
              {errors.meeting_mode && (
                <span role="alert" className="text-danger">
                  {t("Le mode réunion est requis")}
                </span>
              )}
            </Form.Group>
          </div>
          <div>
            <label className="form-label">
              {t("No one at the meeting")}
              <MdAdd
                className=" ms-2"
                size={20}
                onClick={() => append({ key: "", value: "" })}
                Option
              />
            </label>

            {fields.map((item, index) => {
              return (
                <>
                  <div className="card mb-3"  key={index}>
                    <div className="card-body bg-light">
                      <div className="row mb-2" key={item.id}>
                        <div className="row">
                          <div className="d-flex justify-content-end">
                            <FaRegTrashAlt
                              className="text-danger ms-1 mb-2"
                              size={15}
                              onClick={() => remove(index)}
                              Remove
                            />
                          </div>
                        </div>

                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="schedule_date"
                          >
                            <Controller
                              control={control}
                              name={`email_people.${index}.key`}
                              rules={{ required: t("Champs requis") }}
                              render={({ field }) => (
                                <>
                                  <Select
                                    placeholder={t("Sélectionner")}
                                    {...field}
                                    options={
                                      employeelist?.length
                                        ? employeelist
                                        : empemail
                                    }
                                    onChange={(e) => {
                                      field.onChange(e);
                                      setValue(
                                        `email_people[${index}].value`,
                                        e.email
                                      );
                                    }}
                                  />

                                  {errors?.email_people?.[index]?.key && (
                                    <p className="text-danger">{t("Champs requis")}</p>
                                  )}
                                </>
                              )}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Controller
                            control={control}
                            name={`email_people[${index}].value`}
                            rules={{ required:t("Champs requis") }}
                            render={({ field }) => (
                              <Form.Control
                                {...field}
                                type="email"
                                placeholder="Courriel"
                                className="placeholder-color"
                              />
                            )}
                          />
                          {errors?.email_people?.[index]?.value && (
                            <p className="text-danger">{t("Champs requis")}</p>
                          )}
                        </Col>
                      </div>
                    </div>
                  </div>
                  {serverErrortype?.length && serverErrortype?.filter((x) => x.type == "EMPLOYEE")[0]
                    ?.index == watch(`email_people.${index}.key`)?.value ? (
                    <div className="text-danger mb-2 text-center">
                     Ce rendez-vous est un conflit avec un autre rendez-vous de votre calendrier                   </div>
                  ) : ""}
                </>
              );
            })}
          </div>

          <div className="col-md-3">
            <div className="col-md-3">
              <button
                className="btn btn-primary btn-sm"
                type="submit"
                style={{ width: "120px" }}
                disabled={loader}
              >
                {loader && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                {t("send")}
              </button>
            </div>
          </div>
        </Form>
      </Container>
    </>
  );
}
