import React from "react";
 
import { ContainerLogin, LoginCard, RightCol } from "./LoginCard";
import BackgroundImage from "../../../asset/bg.jpg";
import Logo from "../../../asset/logo.png";

function ForgetWrapper(props) {
  return (
    <div className="half-page-image-container">
      <LoginCard style={{ padding: "0px", borderRadius: "2%" }}>
        <RightCol md={7} className="">
          <ContainerLogin>
            <div className="text-center">
              <img className="logo-image" src={Logo} alt="logo" />
              <div className="h5 mt-4 text-uppercase">Mot de passe oublié</div>
            </div>
            {props.children}
          </ContainerLogin>
        </RightCol>
      </LoginCard>

      <div className="half-page-image">
        <div style={{ backgroundImage: `url('${BackgroundImage}')` }}>
          <div className="overlay">
            <div className="quotes">
              <p className="short-about fw-bold">
                <span style={{ fontSize: "20px", color: "yellow" }}>
                  &#10077;{" "}
                </span>
                Il s'agit d'un logiciel web pour les bases de données clients et
                les statistiques. Il prend en charge une variété de types de
                champs standard, s'intègre à plusieurs sources de données et
                génère des rapports en fonction des besoins des utilisateurs.
                <span style={{ fontSize: "20px", color: "yellow" }}>
                  {" "}
                  &#10078;
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetWrapper;
