import React from "react";
import Appointment from "../components/newcustomer/calender/AdvancedCalendar/AdvancedCalendar";
import Nav from "react-bootstrap/Nav";
import Breadcrumbs from "../../../Application/Breadcrumbs";
import { Route, Routes, NavLink } from "react-router-dom";
import ClientLookup from "./clients";
import InterveneLookup from "./intervene";
import AdministrationLookup from "./administration";
import MessageLookup from "./message";
import { Card } from "react-bootstrap";
import ActivityLookup from "./activity";
import ContactLookup from "./contact";
import ClientForm from "./contact/ClientForm";
import { useTranslation } from "react-i18next";

function Clients() {
  const [selectedTab, setSelectedTab] = React.useState("customers");
  const handleTabSelect = (selectedTabKey) => {
    setSelectedTab(selectedTabKey);
  };
  const { t } = useTranslation("global");

  return (
    <>
 
     <div className="">
     <Breadcrumbs
        paths={[
          { label: "Home", link: "/" },
          { label: `${t("Nouveau client")}`, link: "/clients/customers" }
        ]}
        activePath={`/clients/customers`}
        
      />
     </div>
      <Nav
        variant="pills"
        className="mt-2"
        activeKey={selectedTab}
        onSelect={handleTabSelect}
      >
        <Nav.Item>
          <NavLink
            to="/clients/customers"
            className="nav-link"
            exact
            activeClassName="active"
          >
            Clients
          </NavLink>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            to="/clients/contact"
            className="nav-link"
            activeClassName="active"
          >
            Contact
          </NavLink>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            to="/clients/appointment"
            className="nav-link"
            activeClassName="active"
          >
            {" "}
           {t("Rendez-vous")}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink
            to="/clients/intervener"
            className="nav-link"
            activeClassName="active"
          >
            Intervention{" "}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink
            to="/clients/administration"
            className="nav-link"
            activeClassName="active"
          >
            Administration{" "}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink
            to="/clients/activity"
            className="nav-link"
            activeClassName="active"
          >
            {t("Activité")}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink
            to="/clients/message"
            className="nav-link"
            activeClassName="active"
          >
            Message{" "}
          </NavLink>
        </Nav.Item>
      </Nav>

      <Routes>
        <Route
          path="/customers"
          element={<ClientLookup type="customers" title={"Clients"} />}
        />

        <Route
          path="/contact"
          element={<ContactLookup type="contact" title={"Contact"} />}
        />

        <Route
          path="/appointment"
          element={
            <Card className="mt-3"  style={{height:"110dvh"}}>
              <Card.Body >
                <Appointment type="appointment" title={"Rendez-vous"} />
              </Card.Body>
            </Card>
          }
        />
        <Route
          path="/intervener"
          element={<InterveneLookup type="intervener" title={"Intervenante"} />}
        />
        <Route
          path="/intervener/:inter_id"
          element={<InterveneLookup type="intervener" title={"Intervenante"} />}
        />
        <Route
          path="/administration"
          element={
            <AdministrationLookup
              type="administration"
              newclient={true}
              title={"Administration"}
            />
          }
        />

        <Route
          path="/activity"
          element={
            <ActivityLookup
              type="activity"
              newclient={true}
              title={"Activité"}
            />
          }
        />

        <Route path="/message" element={<MessageLookup />} />
        <Route
          path="/administration/:admini_id"
          element={
            <AdministrationLookup
              type="administration"
              title={"Administration"}
            />
          }
        />
        <Route
          path="/activity/:activity_id"
          element={<ActivityLookup type="activity" title={"Activité"} />}
        />
        <Route
          path="/contact/:contact_id"
          element={<ContactLookup type="contact" title={"Contact"} />}
        />
          <Route
          path="/contact/createclient"
          element={<ClientForm/>}
        />
      </Routes>
    </>
  );
}

export default Clients;
