import axios from 'axios';
import { basePort } from './baseURL';

const instance = axios.create({ baseURL: basePort });

instance.interceptors.request.use(
  (config) => {
    
    const token = sessionStorage.getItem('user');

     const user = JSON.parse(token);
     const auth_token = user?.accessToken
    if (auth_token) {
      config.headers.Authorization = `Bearer ${auth_token}`;
    }

    return config;
  },
  (error) => {
     return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
     return response;
  },
  (error) => {
     return Promise.reject(error);
  }
);

export default instance;
