import React from "react";
import { Offcanvas } from "react-bootstrap";
import { appointmentLabel } from "../../../labels";
import AppointmentCalendarForm from "../AppointmentCalendarForm";
import { useTranslation } from "react-i18next";

const ClientComponent = ({ closesidebar, showSidebar }) => {
  const { t } = useTranslation('global');
  return (
    <Offcanvas
      scroll={true}
      backdrop={false}
      show={showSidebar}
      placement="end"
      onHide={() => {
        closesidebar();
      }}
    >
      <Offcanvas.Header closeButton>
      
  <Offcanvas.Title>{t("clienttitle")} </Offcanvas.Title>
</Offcanvas.Header>

      <Offcanvas.Body>
        <AppointmentCalendarForm
          onHide={() => {
            closesidebar();
          }}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
};
export default ClientComponent;
