import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
   import { convertDateformate } from '../../../../../utils';
import Loader from '../../../../../utils/Loader';
import useSettings from '../../../../../hooks';
import clientService from '../../../../../services/clientservice';
import { activityLabel, intervenanteLabel } from '../../components/newcustomer/labels';
import { useTranslation } from 'react-i18next';
  
function ViewActivity({id,setAddCustomer,setClientActivityId}) {
    const [activitylist, setActivityList] = React.useState([]);
    const navigate = useNavigate();
    const [loader,setLoader] = React.useState(false);
    const {   activity } = useSettings();
// const [clientname,setClientName] =useState([])
    const changeNavigation=(eid)=>{
        setAddCustomer(true)
       if(!id) navigate(`/clients/activity/${eid}`)
       else{ 
        setClientActivityId(eid);
     }
      }
      const getAdmindetails = (data, defaultdata) => {
        const recordsplit = data?.split(",");
        const arr = [];
        recordsplit?.length && recordsplit.map((parter) => {
          const matchingItem = defaultdata.find((item) => item?.value === parter);
    
          if (matchingItem) {
            arr.push(matchingItem.label);
          }
        });
        return arr.join(" , ");
      };
    React.useEffect(()=>{
        setLoader(true)
        if(id){
          clientService
          .getActivityByclientId(id)
          .then((intervient) => {
            setActivityList(intervient);
            setLoader(false)

          })
          .catch((error) => {
            console.error(error);
          });
        }
        else{
          clientService
          .getActivity()
          .then((admini) => {
            if (admini) {setActivityList(admini);
              setLoader(false)
            }
            else setActivityList([]);
          })
          .catch((error) => {
            console.error(error);
          });
        } 
        },[id , activity ])
        const { t } = useTranslation("global");

        
   return (
    <Table striped>
    <thead>
      <tr>
        <th></th>
        <th>{intervenanteLabel?.client}</th>
         <th>{t("Activités")}</th>
         
        <th>{t("Date d’activité")}</th>
        <th>{t("Créé sur")}</th>
       


      </tr>
    </thead>
    <tbody>
      {activitylist?.length ? (
        activitylist?.map((admin, idx) => (
          <tr
            key={idx}
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeNavigation(admin?.activity_id)
            }}
          >
            <td>{idx + 1}</td>
            <td>{admin?.Client?.full_name || "-"}</td>
              <td>{getAdmindetails(admin?.activitylist, activity)} </td>
              <td>{convertDateformate(admin?.date_of_activity /1000)}</td>
             <td> {convertDateformate(admin?.added_on) || "-"}</td>
             
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={6} className="text-center">
             {loader ? <Loader/> : "Aucun activité trouvé"}

          </td>
        </tr>
      )}
    </tbody>
  </Table>
  )
}

export default ViewActivity