import React from "react";
import clientService from "../../../../../services/clientservice";
import { useFieldArray, useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { Col, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { BsSave2 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import useSettings from "../../../../../hooks";
import { extraValues } from "../utils";
import reportservice from "../../../../../services/reportservice";
import { useTranslation } from "react-i18next";
import GetTranslatedConstants from "../../components/newcustomer/components/contant1";
 



function AddContact({ setAddCustomer }) {
  const navigate = useNavigate();
  const { contact_id } = useParams();
  const { empemail } = useSettings();
  const [loader, setLoader] = React.useState(false);
  const { listofGender,CATEGORY, martial_status, frequency_services,preferred_language,typeOfOption } = GetTranslatedConstants();

  const { t } = useTranslation("global");
  const {
    register,
    control,watch,
    handleSubmit,
    formState,
     setValue,
    setError,
  } = useForm( 
   );
  const { fields, append, remove } = useFieldArray({
    control,
    name: "employee_email_id",
  });
  const employees = watch("employee_email_id");
 
  
 
  const onSubmit = async (data) => {
    setLoader(true);
    if (contact_id) {
      data.category = data?.category?.value;
       data.employee_emails = data?.employee_emails?.map(x=>x?.value)?.toString()

      const x = data?.employee_email_id
      ? data?.employee_email_id
      : "";
    const flattenedArray = [].concat(...x);
     const emails = data?.employee_email_id
      ?.map((x) => x?.value)
      ?.toString();
     data.employee_emails = emails;

    if (flattenedArray?.find((x) => x.key != "")) {
      const employeeEmailId = await extraValues(
        flattenedArray?.map((x) => x?.key),
        "EMPLOYEE"
      );

      data.employee_email_id = employeeEmailId;
    } else {
      data.employee_email_id = "";
    }

      const refered_team_emaillist = data?.team_involved
      ?.map((x) => x?.value)
      ?.toString();
    data.team_involved_email = refered_team_emaillist;
  

      clientService
        ?.updateContact(contact_id, data)
        .then(async(res) => {
           if (res) {
             
            data.addedon=res?.added_on;
           const {msg}= await reportservice.sentcontactmessage(data);
           if(msg){
            toast.success("Contact enregistré et email envoyé avec succès");
            setAddCustomer(false);
            setLoader(false);
            navigate("/clients/contact");
           }
          }
        })
        .catch((error) => {
          toast.error(Object.values(error?.msg[0]));
          setLoader(false);
        });
    } else {

      delete data.employee_email_id
      clientService
        ?.addContact(data)
        .then((res) => {
          if (res) {
            toast.success("Contact ajouté avec succès");
            setAddCustomer(false);
            setLoader(false);
            navigate("/clients/contact");
          }
        })
        .catch((error) => {
          toast.error(Object.values(error?.msg[0]));
          setLoader(false);
        });
    }
  };
 
  React.useEffect(() => {
    if (employees?.length == 0) {
      append({ key: "", value: "" });
    }
  }, [employees]);
  React.useEffect(() => {
    if (contact_id) {
      clientService.getContactById(contact_id).then((res) => {
        if (res) {
           setValue("client_name", res.client_name);
          setValue("email", res.email);
          setValue("telephone", res.telephone);
          setValue("comments", res?.comments);
          const contact_category = CATEGORY?.filter(
            (x) => x.value === res?.category
          );
          setValue("category", contact_category);
        

        const employee_email_id_data = res?.employee_email_id?.length ?
        res?.employee_email_id.split(",")?.map((x) => x):[];
      const employee_email_id_data_patch = empemail?.filter((data) =>
      employee_email_id_data?.some((x) => x == data.value)
      );
      const ref_email = res?.employee_emails?.split(",");
      const employeeEmails = employee_email_id_data_patch.map(
        (item, i) => ({
          key: item,
          value: ref_email[i],
        })
      );
      setValue("employee_email_id", employeeEmails);
        
        }
      });
    }
  }, [contact_id,empemail]);

  return (
    <>
      {/* Nom */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group className="mb-2" controlId="firstname">
              <Form.Label className="text-secondary fw-bold">  {t("Nom")} *</Form.Label>{" "}
              <Form.Control
                type="text"
                {...register("client_name", {
                  required: t("Ce champ est obligatoire"),
                })}
              />
              {formState?.errors?.client_name && (
                <p className="text-danger">
                  {formState?.errors?.client_name.message}
                </p>
              )}
            </Form.Group>
          </Col>
          {/* Courriel */}
          <Col md={6}>
            <Form.Group className="mb-2" controlId="email">
              <Form.Label className="text-secondary fw-bold">
              {t("Courriel")} *
              </Form.Label>
              <Form.Control
                type="email"
                {...register("email", {
                  required: t("Ce champ est obligatoire"),
                })}
              />
              {formState?.errors?.email && (
                <p className="text-danger">
                  {formState?.errors?.email?.message}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group className="mb-2" controlId="username">
              <Form.Label className="text-secondary fw-bold">
              {t("Téléphone")} *
              </Form.Label>

              <Controller
                name="telephone"
                control={control}
                defaultValue=""
                rules={{ required: t("Ce champ est obligatoire") }}
                render={({ field }) => (
                  <>
                    <Form.Control
                      className="placeholder-color"
                      {...field}
                      type="text"
                      maxLength={14}
                      placeholder="XXX-XXX-XXXX"
                      pattern="\d{3}-\d{3}-\d{4}"
                      onChange={(e) => {
                        const unformattedValue = e.target.value.replace(
                          /\D/g,
                          ""
                        );
                        const formattedValue = unformattedValue.replace(
                          /(\d{3})(\d{3})(\d{4})/,
                          "$1-$2-$3"
                        );
                        field.onChange(formattedValue);
                        if (formState.errors["telephone"]) {
                          setError("telephone", {
                            type: "manual",
                            message: "",
                          });
                        }
                      }}
                    />
                    {formState.errors.telephone && (
                      <p className="text-danger">
                        {formState.errors.telephone.message}
                      </p>
                    )}
                  </>
                )}
              />
            </Form.Group>
          </Col>

          {contact_id ? (

            <Col md={6}>
              <Form.Group className="mb-2" controlId="password">
                <Form.Label className="text-secondary fw-bold">
                   {t("Catégorie")}
                </Form.Label>
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable
                      {...field}
                      options={CATEGORY}
                      formatCreateLabel={(inputValue) =>
                        `Créer "${inputValue}"`
                      }
                      placeholder={t("Sélectionner")}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          ) : <Col md={6}>
            <Form.Group className="mb-2" controlId="password">
              <Form.Label className="text-secondary fw-bold">
                {t("Commentaires ou notes")}*
              </Form.Label>
              <Form.Control
                type="text"
                {...register("comments", {
                  required: t("Ce champ est obligatoire"),
                })}
              />
            </Form.Group>
            {formState?.errors?.comments && (
              <p className="text-danger">
                {formState?.errors?.comments?.message}
              </p>
            )}
          </Col>}
        </Row>
        <Row>

          {contact_id ? (
            <><Col md={6}>
              <label className="form-label text-secondary fw-bold">
              {t("Équipe impliquée")}
                <FaPlus
                  className=" ms-2"
                  size={15}
                  onClick={() => append({ key: "", value: "" })}
                  Add
                  Option
                />
              </label>

              {fields.map((field, index) => (
                <div className="row mb-2" key={field.id}>
                  <div className="col-md-5">
                    <Controller
                      control={control}
                      name={`employee_email_id[${index}].key`}
                      render={({ field }) => (
                        <Select
                          placeholder={t("Sélectionner")}
                          {...field}
                          formatCreateLabel={(inputValue) =>
                            `Créer "${inputValue}"`
                          }
                          options={empemail}
                          onChange={(e) => {
                            setValue(`employee_email_id[${index}].key`, e);
                            setValue(
                              `employee_email_id[${index}].value`,
                              e.email
                            );
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-5">
                    <Form.Control
                      type="email"
                      {...register(`employee_email_id[${index}].value`)}
                      placeholder={t("Courriel")}
                      className="placeholder-color"
                    />
                  </div>
                  <div className="col-md-2">
                    <FaRegTrashAlt
                      className="text-danger ms-2"
                      size={15}
                      onClick={() => remove(index)}
                      Remove
                    />
                  </div>
                </div>
              ))}
            </Col>

              <Col md={6}>
                <Form.Group className="mb-2" controlId="password">
                  <Form.Label className="text-secondary fw-bold">
                    {t("Commentaires ou notes")} *
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register("comments", {
                      required: t("Ce champ est obligatoire"),
                    })}
                  />
                </Form.Group>
                {formState?.errors?.comments && (
                  <p className="text-danger">
                    {formState?.errors?.comments?.message}
                  </p>
                )}
              </Col>
            </>
          ) : ""
          }
        </Row>
        <Row className="g-0 mt-2">
          <Col md={3}>
            <button
              className="btn btn-primary btn-sm  "
              
              disabled={loader}
              type="submit"
            >
              {loader && (
                  <span className="spinner-border spinner-border-sm me-2"></span>
                )}

              <BsSave2 className="me-1" />{" "}
              {contact_id ? t("Enregistrer et envoyer") : "Sauvegarder"}
            </button>
          </Col>
        </Row>
      </form>
    </>
  );
}

export default AddContact;
