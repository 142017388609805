import moment from "moment";
import { momentLocalizer } from "react-big-calendar";

export const eventStyleAppointmentCalendarGetter = (
  event,
  start,
  end,
  isSelected
) => {
  let style = {
    backgroundColor: "",
    borderRadius: "5px",
    opacity: 0.8,
    color: "white",
    border: "0",
    display: "block",
    outline: "",
  };

  if (isSelected && event?.type == "EMPLOYEE") {
    style.outline = "1px auto #FE7A36";
  } else if (isSelected && event.appointment_id) {
    style.outline = "1px auto #adcff0";
  }

  if (event?.type == "EMPLOYEE") {
    style.backgroundColor = "#FFF9C9 ";
    style.color = " #E36414";
    style.border = "1px solid #FFBB64";
    if (event?.meeting_person) {
      style.backgroundColor = "#FFB534";
      style.color = "#BE3144";
      style.border = "1px solid #FFB534";
    }
  } else if (event.appointment_id) {
    style.backgroundColor = "#2571b8 ";
    style.color = " #ffff";
    style.border = "1px solid #adcff0";
  }

  return {
    style,
  };
};

export const eventSchedularCalendarStyleGetter = (
  event,
  start,
  end,
  isSelected
) => {
  let style = {
    backgroundColor: "",
    borderRadius: "5px",
    opacity: 0.8,
    color: "white",
    border: "0",
    display: "block",
    outline: "",
  };

  if (isSelected && event.appointment_id && event?.type != "EMPLOYEE")
    style.outline = "1px auto #adcff0";
  else if (isSelected && event.appointment_id == null)
    style.outline = "1px auto #fff";
  else if (isSelected && event.type == "EMPLOYEE")
    style.outline = "1px auto #FFB534";

  if (event.appointment_id) {
    style.backgroundColor = "#2571b8 ";
    style.color = " #fff";
    style.border = "1px solid #adcff0";
  } else if (event.appointment_id == null) {
    style.backgroundColor = "#505050";
    style.color = "#fff";
    style.border = "1px solid #bbbb";
  }

  if (event?.type == "EMPLOYEE" && event?.meeting_person) {
    style.backgroundColor = "#FFB534";
    style.color = "#BE3144";
    style.border = "1px solid #FFB534";
  }

  if (event?.type == "EMPLOYEE" && event?.meeting_person == null) {
    style.backgroundColor = "#FFF9C9 ";
    style.color = " #E36414";
    style.border = "1px solid #FFBB64";
  }

  return {
    style,
  };
};

const localizer = momentLocalizer(moment);



const CustomWeekHeader = ({ label }) => {
  const capitalizedLabel = label.charAt(0).toUpperCase() + label.slice(1);

  return <strong>{capitalizedLabel}</strong>;
};

 
 
const CustomDay = ({ date }) => {
  const formattedDate = moment(date).format('D MMM');
  return <strong >{formattedDate}</strong>;
};

const CustomMonthHeader = ({ label }) => {
  const capitalizedLabel = label.charAt(0).toUpperCase() + label.slice(1);
  return <strong  >{capitalizedLabel}</strong>;
};

const eventMeetingperson = (person, empemail) => {
  const isPersonArr = person?.split(",");
  if (!isPersonArr) {
    return empemail?.filter((x) => x.value === person)[0]?.label;
  } else {
    const meeting_person_arr = isPersonArr.map(
      (y) => empemail?.filter((x) => x?.value === y)[0]?.label
    );
    return meeting_person_arr.join(", ");
  }
};

export { CustomWeekHeader, CustomDay,CustomMonthHeader, localizer, eventMeetingperson };
