import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { refreshAccessToken } from '../store/slices/authslice';
import {useDispatch} from "react-redux";

function ExpirePopup(props) {
    const dispatch = useDispatch()
    const refreshHandler=()=>{
        dispatch(refreshAccessToken());
        props.handlePopupClose();

    }
  return (
    <Modal
    show={props?.tokenExpiredPopup}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Modal heading
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h4>Centered Modal</h4>
      <p>
         
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={()=>{refreshHandler()}}>Close</Button>
    </Modal.Footer>
  </Modal>
  )
}

export default ExpirePopup