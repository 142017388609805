import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../../services/authservice/authservice";
import { setMessage } from "./messageslice";

  

const userJson = sessionStorage && sessionStorage.getItem("user");
const user = userJson && JSON.parse(userJson);

 export const login = createAsyncThunk(
  "auth/login",
  async (authdata, thunkAPI) => {
    try {
      const response = await authService.loginauthentication(authdata);
      if (response) {
         return response;
      } else {
        thunkAPI.dispatch(setMessage("Unauthorized"));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const refreshAccessToken = createAsyncThunk(
  "auth/refreshAccessToken",
  async (refreshToken, thunkAPI) => {
    try {
      const response = await authService.refreshAccessToken(refreshToken);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const logout = createAsyncThunk("auth/logout", async () => {
  await authService.logout();
});

 
const authSlice = createSlice({
  name: "auth",
    initialState : user
  ? { isLoggedIn: true, user }
  : {
      isLoggedIn: false,
      user: null,
      accessToken: null,
      refreshToken: null,
      role:null,
      isAuthenticated: false,
      tokenExpiredPopup: false,
    },
  reducers: {
    showTokenExpiredPopup: (state) => {
      state.tokenExpiredPopup = true;
    },
    hideTokenExpiredPopup: (state) => {
      state.tokenExpiredPopup = false;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(login.fulfilled, (state, action) => {
       if (action.payload) {
        state.isLoggedIn = true;
        state.user = action?.payload?.username;
        state.role=action?.payload?.roles;
        state.accessToken = action?.payload?.accessToken;
        state.refreshToken = action?.payload?.accessToken;
        state.isAuthenticated = true;
        }
      })
      .addCase(login.rejected, (state) => {
        state.isLoggedIn = false;
        state.user = null;
        state.role = null;
        state.accessToken = null;
        state.refreshToken = null;
        state.isAuthenticated = false;
      })
      .addCase(refreshAccessToken.fulfilled, (state, action) => {
        state.accessToken = action?.payload?.accessToken;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.user = null;
        state.role = null;
        state.accessToken = null;
        state.refreshToken = null;
        state.isAuthenticated = false;
      });
  },
});
export const {  showTokenExpiredPopup, hideTokenExpiredPopup } = authSlice.actions;

export const selectAuth = (state) => state.auth;

const { reducer } = authSlice;
export default reducer;
