import React from "react";
import Breadcrumbs from "../../../Application/Breadcrumbs";
import "./admin.module.css";
import { Card, Col, Row, ProgressBar } from "react-bootstrap";
import appointmentservice from "../../../../services/appointmentservice";
import LineChart from "../charts/LineChart";
import RadialChart from "../charts/RadialChart";
import PieChart from "../charts/PieChart";
import DashboardCards from "./DashboardCards";
import clientService from "../../../../services/clientservice";
import Loader from "../../../../utils/Loader";
import DonetChart from "../charts/DonetChart";
import { useTranslation } from "react-i18next";

function Dashboard() {
  const [appointmentcount, setAppointmentcount] = React.useState(0);
  const [clientcount, setClientcount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [dashboardresult, setDashboradresult] = React.useState(null);

  const [registrationdata, setRegistrationdata] = React.useState(0);

  const getColor = (index) => {
    const colors = ["danger", "success", "warning", "info"];
    return colors[index % colors.length];
  };
  React.useEffect(() => {
    setLoading(true);
    clientService
      .getDashboardInfo()
      .then((res) => {
        if (res) {
          setRegistrationdata(res?.registration);
          setDashboradresult(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const totalCount = dashboardresult?.partners?.data.reduce(
    (total, count) => total + count?.COUNT,
    0
  );
   const { t } = useTranslation("global");
  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center" style={{ height: "70vh" }}>
          <Loader />
        </div>
      ) : (
        <Row>
          <DashboardCards
            appointmentcount={appointmentcount}
            clientcount={clientcount}
            dashboardresult={dashboardresult}
          ></DashboardCards>
          <Col md={6} className="mb-2">
            <Card
              className="mb-4 h-100 flex-fill border-0 rounded-4"
              style={{ backgroundColor: "#f8f9fb" }}
            >
              <Card.Body>
                <Card.Title className={`text-black fw-bold`}>
                  {t("Nombre d ’inscriptions")}
                </Card.Title>
                <div className={"d-flex"}>
                  <div className={"me-5"}>
                    <Card.Text className={"fw-semibold"}>
                      {t("Cette Semaine")}
                    </Card.Text>
                    <Card.Text className={"fw-bold"}>
                      {registrationdata?.currentweek?.count}
                    </Card.Text>
                  </div>
                  <div className={"me-2"}>
                    <Card.Text className={"fw-semibold"}>
                      {t("Le mois dernier")}
                    </Card.Text>
                    <Card.Text className={"fw-bold"}>
                      {registrationdata?.lastmonth?.count}
                    </Card.Text>
                  </div>
                </div>
                <LineChart registrationlist={registrationdata} />
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="mb-2">
            <Card
              className="me-3 h-100 w-100 flex-fill rounded-4"
              style={{ backgroundColor: "#44715c" }}
            >
              <Card.Body className="d-flex">
                <div className="flex-grow-1">
                  <Card.Title className={`text-white`}>
                    {t("Partenaires recommandés")}
                  </Card.Title>
                  {dashboardresult?.partners?.data.map((item, index) => {


                    const percentageData = (item?.COUNT / totalCount) * 100;
                    
                    return (
                      <div key={index} className="mb-3">
                        <div className="d-flex justify-content-between text-white">
                          <div className="text-white">{item.KEY}</div>
                          <div>{item.value}</div>
                        </div>
                        <ProgressBar
                          striped
                          variant={getColor(index)}
                          now={percentageData }
                          label={`${parseFloat(percentageData).toFixed(2)}%`}
                          className="mb-2"
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="my-auto " style={{width:"70%"}}>
                  <RadialChart
                  
                    label={
                      dashboardresult?.partners?.data?.length
                        ? dashboardresult?.partners?.data?.map((Y) => Y?.KEY)
                        : []
                    }
                    series={
                      dashboardresult?.partners?.data?.length
                        ? dashboardresult?.partners?.data?.map((Y) => Y?.COUNT)
                        : []
                    }
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col md={6} className=" d-flex">
          <Card className="border-0 h-100 flex-fill rounded-4" style={{ backgroundColor: "#f8f9fb" }}>
            <Card.Body>
              <Card.Title className={``}>Clients – Pays</Card.Title>
              <PieChart />
            </Card.Body>
          </Card>
        </Col> */}
        </Row>
      )}
    </>
  );
}

export default Dashboard;
