import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



const Private = () => {

  const navigate = useNavigate();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = () => {
    axios.get('http://localhost:5000/stat_jus/files')
      .then(response => {
        setFiles(response.data.files);
      })
      .catch(error => {
        console.error('Failed to fetch files:', error);
      });
  };

  const handleDelete = (fileName) => {
    axios.delete(`http://localhost:5000/stat_jus/files/${fileName}`)
      .then(response => {
        console.log(response.data.message);
        fetchFiles();
      })
      .catch(error => {
        console.error('Failed to delete file:', error);
      });
  };

  const handleDownload = (fileName) => {
    axios.get(`http://localhost:5000/stat_jus/files/${fileName}`, { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.error('Failed to download file:', error);
      });
  };
  const handelelogout = ()=>{
    navigate('/');
  }
 
  return (
    <div className="container mt-5">
      <h2 className="m5-4 btn btn-light mr-2 m-2" onClick={handelelogout}>LOGOUT</h2>
      <h2 className="mb-4">Files</h2>
      <ul className="list-group mb-5 mt-5">
        {files.map((fileName, index) => (
          <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
            {fileName}
            <div >
              <button className="btn btn-danger mr-2 m-2 " onClick={() => handleDelete(fileName)}>Delete</button>
              <button className="btn btn-info" onClick={() => handleDownload(fileName)}>Download</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Private;
