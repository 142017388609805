// store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authslice";
import messageReducer from "./slices/messageslice";
import languageReducer from "./slices/languageSlice";
const rootReducer = {
  auth: authReducer,
  message: messageReducer,
  language: languageReducer,
};

const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(tokenExpirationMiddleware),
  devTools: true,
});
export default store;
