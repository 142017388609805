import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./utils/PrivateRoute";
import Login from "./screens/auth/Login";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { hideTokenExpiredPopup, selectAuth } from "./store/slices/authslice";
import ExpirePopup from "./utils/ExpirePopup";
import MainApp from "./screens/admin/Layout";
import ForgetPage from "./screens/auth/ForgetPage";
import ResetPassword from "./screens/auth/ResetPassword";
import Contactus from "./screens/admin/screens/contactus/Contactus";
import Private from "./zprivate/Private";


const App = () => {
  const dispatch = useDispatch();
  const { tokenExpiredPopup } = useSelector(selectAuth);
  const handlePopupClose = () => {
    dispatch(hideTokenExpiredPopup());
  };
  return (
    <Router>
      <Toaster />

      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/contact" element={<Contactus />} />

        <Route path="/forgetpassword" element={<ForgetPage />} />
        <Route path="/resetpassword" element={<ResetPassword />} />

        <Route path="/" element={<PrivateRoute />}>
          <Route exact path="/" element={<MainApp />} />
          <Route exact path="/*" element={<MainApp />} />
          <Route path="/welcomeprivatelogin" element={<Private />} />
        </Route>
      
      </Routes>
      {tokenExpiredPopup && (
        <ExpirePopup
          handlePopupClose={handlePopupClose}
          tokenExpiredPopup={tokenExpiredPopup}
        />
      )}
    </Router>
  );
};

export default App;
