import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { Col, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { BsSave2 } from "react-icons/bs";
import clientService from "../../../../services/clientservice";
import { useNavigate } from "react-router-dom";
import "./style.css";
import logo from "../../../../asset/afio.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../../store/slices/languageSlice";

function Contactus() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, control, handleSubmit, formState, errors, setError } =
    useForm();
  const { t } = useTranslation("global");
  const onSubmit = async (data) => {
    clientService
      ?.addContactlist(data)
      .then((res) => {
        if (res) {
          toast.success("Votre message a été envoyé avec succès");
          window.location.replace("https://afio.ca/");
        }
      })
      .catch((error) => {
        toast.error(Object.values(error?.msg[0]));
      });
  };
  const changeLanguageHandler = (e) => {
    dispatch(setLanguage(e));
  };
  return (
    <>
      <div className={`container text-white d-flex justify-content-center `}>
        <button
          className={`btn btn-primary m-1 `}
          onClick={() => changeLanguageHandler("en")}
        >
          English
        </button>
        <button
          className={`btn btn-primary  m-1 `}
          onClick={() => changeLanguageHandler("fr")}
        >
          French
        </button>
      </div>
      <div className="container-fluid my-3 text-center ">
        <img src={logo} className="mb-3 "></img>
        <h4 class="  text-center mb-3" style={{ color: "#662730" }}>
          {t("Il nous fera plaisir de discuter avec vous")}
        </h4>

        <form onSubmit={handleSubmit(onSubmit)} className="container mt-3 ">
          <Row className="mb-4  justify-content-center">
            <Col md={5}>
              <Row md={12} className="mb-3  ">
                <p className="style-contact-heading">
                  {t(
                    "Pour une question ou toute autre demande, veuillez remplir le formulaire ci- dessous. Un membre de notre équipe se fera le plaisir de vous répondre le plus rapidement possible."
                  )}
                </p>
              </Row>
              <Row md={12} className="mb-3 ">
                <Form.Control
                  type="text"
                  placeholder={t("Nom")}
                  {...register("client_name", {
                    required: t("Ce champ est obligatoire"),
                  })}
                />
                {formState?.errors?.client_name && (
                  <p className="text-danger">
                    {formState?.errors?.client_name.message}
                  </p>
                )}
              </Row>

              {/* Courriel */}

              <Row md={12} className="mb-3 ">
                <Form.Control
                  type="email"
                  placeholder={t("Courriel")}
                  {...register("email", {
                    required: t("Ce champ est obligatoire"),
                  })}
                />
                {formState?.errors?.email && (
                  <p className="text-danger">
                    {formState?.errors?.email?.message}
                  </p>
                )}
              </Row>

              <Row md={12} className="mb-3 ">
                <Controller
                  name="telephone"
                  control={control}
                  defaultValue=""
                  rules={{ required: t("Ce champ est obligatoire") }}
                  render={({ field }) => (
                    <>
                      <Form.Control
                        className="placeholder-color"
                        {...field}
                        type="text"
                        maxLength={14}
                        placeholder="XXX-XXX-XXXX"
                        pattern="\d{3}-\d{3}-\d{4}"
                        onChange={(e) => {
                          const unformattedValue = e.target.value.replace(
                            /\D/g,
                            ""
                          );
                          const formattedValue = unformattedValue.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "$1-$2-$3"
                          );
                          field.onChange(formattedValue);
                          if (formState.errors["telephone"]) {
                            setError("telephone", {
                              type: "manual",
                              message: "",
                            });
                          }
                        }}
                      />
                      {formState.errors.telephone && (
                        <p className="text-danger">
                          {formState.errors.telephone.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </Row>

              <Row md={12} className="mb-3 ">
                <Form.Control
                  type="text"
                  rows={4}
                  as="textarea"
                  placeholder={t("Commentaires ou notes")}
                  {...register("comments")}
                />
              </Row>

              <Row className="g-0 mt-2 mb-3 justify-content-center">
                <button
                  className="btn btn-primary  "
                  style={{ width: "120px" }}
                  type="submit"
                >
                  <BsSave2 className="me-1" />
                  {t("Envoyer")}
                </button>
              </Row>
            </Col>
          </Row>
        </form>
        <footer class="  py-1 my-2 border-top fixed-bottom">
          <span class="text-muted">
            <a
              href="https://afio.ca "
              style={{ color: "#662730" }}
              className="text-decoration-none  fw-bold ms-3  "
            >
              www.afio.ca
            </a>
          </span>
        </footer>
      </div>
    </>
  );
}

export default Contactus;
