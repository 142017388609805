 import React from "react";
import Card from "react-bootstrap/Card";
import { BsFillPeopleFill } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";
 
import {useNavigate} from "react-router-dom";
import AddActivity from "./addActivity";
import ViewActivity from "./viewactivity";
import { useTranslation } from "react-i18next";

function ActivityLookup({title ,clientSideupdate ,id}) {
  const navigate = useNavigate();
  const [addcustomer, setAddCustomer] = React.useState(false);
    const [activityclient,setActivityClient] = React.useState("")
     
    const setClientActivityId=(idx)=>{
      setActivityClient(idx)
    }
    const backHandler=()=>{
      if(id){
        setAddCustomer(false);
        setActivityClient("")
      }
     else{
      setAddCustomer(false);
      navigate("/clients/activity")
     }
    }
    const { t } = useTranslation("global");

  return (
    <Card className="mt-3">
      <Card.Header>
    {!addcustomer ? (
      <div className="d-flex m-2 justify-content-between align-items-center">
      <p className="align-items-center h6 fs-12  mt-2">
          {" "}
          <BsFillPeopleFill size={20} className="me-2" />
          {t("Activité")}
        </p>
        <button
          className="btn btn-primary btn-sm"
          onClick={() => setAddCustomer(true)}
        >
          <FiPlus size={20} className="me-2" /> {t("Ajouter activité")}
        </button>
      </div>
    ) : (

      <div className="d-flex justify-content-start m-2">
      <button
          className="btn btn-primary btn-sm"
          onClick={backHandler}
        >
           
          <IoReturnUpBackOutline className="me-1" size={20} />
          {t("Retour")}
        </button>
      </div>
     
    )}
</Card.Header>
    <Card.Body>{addcustomer && !clientSideupdate?
     <AddActivity setAddCustomer={setAddCustomer} id={id} activityclient={activityclient}/> 
     : <ViewActivity  setAddCustomer={setAddCustomer} id={id} setClientActivityId={setClientActivityId}/>}</Card.Body>
  </Card>
  )
}

export default ActivityLookup