import clientService from "../../../../services/clientservice";

const extraValues = async (extra_array, type) => {
    if (extra_array) {
      const rec_prt = [];
      const newparter = extra_array.filter((x) => x.label == x.value);
      const existparter = extra_array.filter((x) => x.label != x.value);
      if (newparter && newparter.length) {
        for await (let parter of newparter) {
          const payload = {
            name: parter?.label,
            type: type,
            entry: "MANUAL",
          };

          const insertpayload = await clientService.insertSetting(payload);
          rec_prt.push(insertpayload.worker_id);
        }
      }
      if (existparter && existparter.length) {
        for await (let parter of existparter) {
          rec_prt.push(parter.value);
        }
      }
      return rec_prt.toString();
    }
  };


  export {extraValues}