import React from "react";
import { Link } from "react-router-dom";
import "./Breadcrumbs.css";
import { FiHome } from "react-icons/fi";

function Breadcrumbs({ paths, activePath }) {
  return (
    <nav className="d-flex justify-content-start" aria-label="breadcrumb">
      <ol className="breadcrumb">
        {paths.map((path, index) => (
          <li
            key={index}
            className={`breadcrumb-item ${
              path.link === activePath ? 'active' : ''
            }`}
          >
            {index === 0 && path.link ? (
              <Link
                to={path.link}
                className={path.link === activePath ? 'active-link' : ''}
              >
                <FiHome color="#ec7026" size={15}/>
              </Link>
            ) : (
              path.link ? (
                <span
               
                  className={` fw-bold h5 text-dark ${path.link === activePath ? 'active-link' : ''}`}
                >
                  {path.label}
                </span>
              ) : (
                <span>{path.label}</span>
              )
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default Breadcrumbs;
