import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { useTranslation } from 'react-i18next';
Chart.register(...registerables);

const LineChart = ({registrationlist}) => {
  const [chartData,setChartData] = React.useState([])
  React.useEffect(() => {
 
    const list =  registrationlist?.month?.length && registrationlist?.month?.map(reg=>reg?.value);
    const label = registrationlist?.month?.length && registrationlist?.month?.map(reg=>reg?.label);
     setChartData((prevChartData) => ({
     ...prevChartData,
     series: list,
     label:label
   }));
 }, [registrationlist]);
 const { t } = useTranslation('global');
  const data = {
    labels: chartData.label,
    datasets: [
      {
        label: t("Inscriptions mensuelles"),

        data: chartData?.series,
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'category',
        labels: chartData.labels,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Line data={data} options={options} height={"100%"} />;
};

export default LineChart;
