import React from 'react'
import Card from "react-bootstrap/Card";
import { BsFillPeopleFill } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { IoReturnUpBackOutline } from "react-icons/io5";
 import AddContact from './addcontact';
import VieWContact from './viewcontact';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ContactLookup({id}) {
    const [addcustomer, setAddCustomer] = React.useState(false);
    const [contactClient,setContactClient] = React.useState("")
  const navigate = useNavigate()
    const setContactClients=(idx)=>{
      setContactClient(idx)
    }
    const { t } = useTranslation("global");

    return (
      <Card className="mt-3">
         <Card.Header>
        {!addcustomer ? (
        <div className="d-flex m-2 justify-content-between align-items-center">
        <p className="align-items-center h6 fs-12  mt-2">
              {" "}
              <BsFillPeopleFill size={20} className="me-2" />
              Contact
            </p>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => setAddCustomer(true)}
            >
              <FiPlus size={20} className="me-2" /> {t("Ajouter Contact")}
            </button>
          </div>
        ) : (
   
          <div className="d-flex justify-content-start m-2">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setAddCustomer(false);
                navigate("/clients/contact")}
               
              }
            >
             
              <IoReturnUpBackOutline className="me-1" size={20} />
              {t("Retour")}
            </button>
          </div>
         
        )}
  </Card.Header> 
        <Card.Body>{addcustomer  ? <AddContact setAddCustomer={setAddCustomer} id={id} contactClient={contactClient}/> 
     : <VieWContact  setAddCustomer={setAddCustomer} id={id} 
     setContactClients={setContactClients}  />}</Card.Body>
      </Card>
    );
}

export default ContactLookup