import instance from "../axiosConfig";

const getAllClient = () => {
  return instance
    .get(`/client`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const getappointmentbyclientid = (id) => {
  return instance
    .get(`/appointment/getbyclientid/${id}`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const getAppointmentsById = (id) => {
  return instance
    .get(`/appointment/getAppointments/${id}`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const getappointmentByEmployee = (data) => {
  return instance
    .post("/appointment/getbyemployee", data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
const createAppointment = (data) => {
  return instance.post(`/appointment`, data).then((response) => {
    if (response.status) {
      return response.data;
    } else {
      return response?.data?.error;
    }
  });
};

const createEmployeeAppointment = (data) => {
  return instance.post(`/appointment/employee`, data).then((response) => {
    if (response.status) {
      return response.data;
    } else {
      return response?.data?.error;
    }
  });
};

const viewAppointment = (id) => {
  if (id) {
    return instance
      .get(`/appointment/${id}`)
      .then((response) => {
        if (response.status) {
          return response.data;
        } else {
          return response?.data?.error;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  } else {
    return instance
      .get(`/appointment`)
      .then((response) => {
        if (response.status) {
          return response.data;
        } else {
          return response?.data?.error;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
};
const updateAppointment = (id, data) => {
  return instance
    .put(`/appointment/${id}`, data)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const deleteAppointments = (id) => {
  return instance
    .delete(`/appointment/${id}`)
    .then((response) => {
      if (response.status) {
        return response.data;
      } else {
        return response?.data?.error;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

const appointmentservice = {
  getappointmentByEmployee,
  getAllClient,
  createAppointment,
  getappointmentbyclientid,
  viewAppointment,
  updateAppointment,
  deleteAppointments,
  getAppointmentsById,
  createEmployeeAppointment,
};
export default appointmentservice;
