import React from "react";
import ReactApexChart from "react-apexcharts";

const MultipleApexCharts = ({ series, label }) => {
  const totalCount = series.reduce((total, count) => total + count, 0);
   const percentageData = series.map(count => (count / totalCount) * 100);
   const [chartData, setChartData] = React.useState({
    series: percentageData,
    options: {
      chart: {
        height: 300,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "20px",
            },

            value: {
              fontSize: "16px",
              color: "white",
              formatter: function (opts) {
                return  parseFloat(opts).toFixed(2) + '%';
              },
            },
            total: {
              show: true,
              label: "Total Count",

              formatter: function (w) {
                const total =totalCount;
                return total > 0 ? total : "0"; 
              },
             
                color: 'white',  
               offsetY: 10,
            },
          },
        },
      },
      labels: label, 
      colors: ["#DC4146", "#4FA845", "#FEC134", "#31A2B8"],
    },
   
    
    fill: {
      colors: ["#FFFFFF"],
    },
  });
   React.useEffect(() => {
    setChartData((prevChartData) => ({
      ...prevChartData,
      series: series,
      options: {
        ...prevChartData.options,
        labels: label,
      },
    }));
  }, [series, label]);

  return (
    <div>
      <div className="chart-container">
        <div className="chart">
          <ReactApexChart
            options={chartData?.options}
            series={percentageData}
            type="radialBar"
            height={280}
          
          />
        </div>
      </div>
    </div>
  );
};

export default MultipleApexCharts;
